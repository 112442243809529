import * as tslib_1 from "tslib";
import { DatePipe } from "@angular/common";
import { OnDestroy, OnInit, ElementRef, } from "@angular/core";
import * as moment from "moment";
import { forkJoin } from "rxjs";
import { map, mergeMap, switchMap } from "rxjs/operators";
export var MY_FORMATS = {
    parse: {
        dateInput: "MM-DD-YYYY",
    },
    display: {
        dateInput: "MM-DD-YYYY",
        monthYearLabel: "MMM YYYY",
        dateA11yLabel: "MM-DD-YYYY",
        monthYearA11yLabel: "MMMM YYYY",
    },
};
var EnterInspectionComponent = /** @class */ (function () {
    function EnterInspectionComponent(dialog, propertyService, router, inspectionService, route, datepipe, loggerService, authorityService, el, utilityService, location) {
        this.dialog = dialog;
        this.propertyService = propertyService;
        this.router = router;
        this.inspectionService = inspectionService;
        this.route = route;
        this.datepipe = datepipe;
        this.loggerService = loggerService;
        this.authorityService = authorityService;
        this.el = el;
        this.utilityService = utilityService;
        this.location = location;
        this.property = {
            name: "",
            address: "",
            emailAddress: "",
            accessCode: "",
            juristrictionName: "",
            jurisdictionId: "",
            manager: { name: "", number: "" },
        };
        this.owner = { name: "", address: "", primaryContact: "" };
        this.certifications = { options: [] };
        this.monitoring = { options: [] };
        this.deficiency = { options: [] };
        this.selectedInspectorID = -1;
        this.prvRow = {};
        this.prvRows = [];
        this.hoseRows = [];
        this.propertyMarker = [];
        this.phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        this.userDetails = null;
    }
    EnterInspectionComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.additionalCertificateList = [];
        this.submitted = false;
        this.assetTypeName = "";
        this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
        this.defOption = "No";
        this.monitoringOption = "No";
        this.userRole = localStorage.getItem("userRole");
        this.reports = [
            { id: "STANDARD_REPORT", data: "Standard Reports" },
            { id: "SIMPLIFIED_REPORT", data: "Simplified Reports" },
        ];
        if (window.location.href.indexOf("addEditProperty") > 0) {
            this.addEditProperty = true;
        }
        else {
            this.addEditProperty = false;
        }
        this.sequence = [];
        this.images = {};
        this.inspectors = [];
        this.systems = [];
        this.subscriptions = [];
        this.inspectionDataforSecondPage = {};
        this.errorMessages = [];
        this.showInspectionQuestions = false;
        this.property.name = "";
        this.property.address = "";
        this.property.emailAddress = "";
        this.property.accessCode = "";
        this.property.juristrictionName = "";
        this.property.manager.name = "";
        this.property.manager.number = "";
        this.owner.name = "";
        this.owner.address = "";
        this.owner.primaryContact = "";
        this.deficiencyList = [];
        this.inspectionResult = {};
        this.inspectionResult.isDeficiencyFound = false;
        this.inspectionResult.isCentralMonitoring = false;
        this.qtd = {};
        this.questions = [];
        this.subscriptions.push(this.propertyService.getAssestTypeOptions().subscribe(function (res) {
            _this.systems = res;
        }));
        this.systemLocation = "";
        this.coverageArea = "";
        this.inspectionFrequency = "Annually";
        this.contractorLicense = "";
        this.certifications.options = [
            {
                id: 7,
                name: "red",
            },
            {
                id: 8,
                name: "yellow",
            },
            {
                id: 9,
                name: "white",
            },
        ];
        this.stateCertificationNumber = "";
        this.nationalNICETNumber = "";
        this.inspectorphoneNum = "";
        /* Central Monitor Details */
        this.monitoring.options = [
            {
                id: 1,
                name: "Yes",
            },
            {
                id: 2,
                name: "No",
            },
        ];
        this.deficiency.options = [
            {
                id: 1,
                name: "Yes",
            },
            {
                id: 2,
                name: "No",
            },
        ];
        /* Deficiencies*/
        this.deficiencies = [{ index: 0 }];
        this.subscriptions.push(this.route.queryParams.subscribe(function (params) {
            var assetId;
            if (_this.router.url.indexOf("fileCabinet") > -1 ||
                _this.router.url.indexOf("dashboardAuthority") > -1) {
                _this.id = _this.viewReportData.id;
                _this.propertyId = _this.viewReportData.propId;
                _this.pac = _this.viewReportData.pac;
                _this.isViewMode =
                    _this.viewReportData.viewMode ||
                        _this.userDetails.accessSpecifier === "true";
                _this.inspectionReportID = _this.viewReportData.editMode;
                _this.isImageUploadEnabled = _this.viewReportData.isImageUploadEnabled;
                _this.isSimplifiedReportEnabled =
                    _this.viewReportData.isSimplifiedReportEnabled;
                _this.inspectionResult.propertyAsset = { id: -1 };
                _this.inspectionResult.propertyAsset.id = _this.viewReportData.assetId;
                assetId = _this.viewReportData.assetId;
                _this.method = params.method;
            }
            else {
                _this.id = params.id;
                _this.propertyId = params.propId;
                _this.pac = params.pac;
                var viewMode = params.viewMode === "true" ? true : false;
                _this.isViewMode =
                    viewMode || _this.userDetails.accessSpecifier === "true";
                _this.inspectionReportID = params.editMode;
                _this.isImageUploadEnabled = params.isImageUploadEnabled === "true";
                _this.isSimplifiedReportEnabled =
                    params.isSimplifiedReportEnabled === "true";
                _this.inspectionResult.propertyAsset = { id: -1 };
                _this.inspectionResult.propertyAsset.id = params.assetId;
                assetId = params.assetId;
                _this.method = params.method;
            }
            _this.qmv = params.qmv;
            if (_this.id) {
                _this.selectedSystemId = _this.id;
                _this.subscriptions.push(_this.inspectionService
                    .getAssetName(_this.selectedSystemId)
                    .subscribe(function (x) {
                    _this.assetTypeName = x["assetName"];
                    _this.inspectionDataforSecondPage.accessSpecifier =
                        x.accessSpecifier;
                }));
                if (_this.selectedSystemId === "5") {
                    _this.commercialHood = true;
                }
                else {
                    _this.commercialHood = false;
                }
                _this.inspectionFrequency = _this.systems.find(function (x) { return x.id === _this.selectedSystemId; })
                    ? _this.systems.find(function (x) { return x.id === _this.selectedSystemId; })
                        .inspectionFrequencyDescription
                    : "";
            }
            if (_this.inspectionReportID === "false" &&
                _this.method != "inspectReport" &&
                _this.method != "editReport") {
                _this.subscriptions.push(_this.inspectionService
                    .getPropertyDetailsById(_this.propertyId)
                    .pipe(map(function (res) {
                    if (res) {
                        _this.property.name = res.propertyName;
                        _this.property.address = res.propertyAddress;
                        _this.property.emailAddress = res.propertyEmail;
                        _this.jurisdicationId = res.jurisdictionId;
                        if (+_this.pac !== 0) {
                            _this.property.accessCode = res.propertyAccessCode;
                        }
                        else {
                            _this.property.accessCode = "";
                        }
                        _this.property.juristrictionName = res.jurisdiction;
                        /* Owner Details*/
                        _this.owner.name = res.propertyOwner;
                        _this.owner.address = res.ownerAddress;
                        _this.owner.primaryContact = res.ownerContactNumber
                            ? res.ownerContactNumber.replace(_this.phoneRegex, "$1-$2-$3")
                            : "";
                        return res;
                    }
                }), mergeMap(function (property) {
                    var questions = _this.inspectionService.getQuestions(_this.id, +property.jurisdictionId, assetId);
                    var questionVersion = _this.inspectionService.getQuestionVersion(_this.id, +property.jurisdictionId);
                    var authSettings = _this.inspectionService.getAuthoritySettings(_this.id, +property.jurisdictionId);
                    if (_this.userRole === "ROLE_INSPECTOR") {
                        _this.subscriptions.push(_this.inspectionService
                            .getInspectorList(+property.jurisdictionId, +assetId)
                            .subscribe(function (res) {
                            if (res) {
                                var inspectors = [];
                                inspectors = res;
                                inspectors.unshift({
                                    id: -1,
                                    firstName: "Select",
                                    middleName: "",
                                    lastName: "",
                                    isBlocked: false,
                                });
                                _this.inspectors = inspectors;
                                var preSelectedInspector = inspectors.find(function (insp) { return insp.isSelected === true; });
                                _this.selectedInspectorID =
                                    preSelectedInspector &&
                                        !preSelectedInspector.isBlocked
                                        ? preSelectedInspector.id
                                        : -1;
                                _this.inspectionResult.inspectedByCertInspectorId =
                                    _this.selectedInspectorID;
                                // TEGFE-3047: certificate details and other fields were not visible when new report is filled.
                                _this.inspectorphoneNum = _this.inspectors.find(function (x) { return x.id === +_this.selectedInspectorID; })
                                    ? _this.inspectors
                                        .find(function (x) { return x.id === _this.selectedInspectorID; })
                                        .phoneNumber.replace(_this.phoneRegex, "$1-$2-$3")
                                    : "";
                                _this.stateCertificationNumber =
                                    _this.inspectors.find(function (x) { return x.id === _this.selectedInspectorID; })
                                        ? _this.inspectors.find(function (x) { return x.id === _this.selectedInspectorID; }).stateCertificate
                                        : "";
                                _this.additionalCertificateList = _this.inspectors.find(function (x) { return x.id === _this.selectedInspectorID && !x.isBlocked && !_this.isInspectorCertificateExpired(x); }) ? _this.additionalCertificateList = _this.inspectors.find(function (x) { return x.id === _this.selectedInspectorID; }).additionalCertificateDtoList : [];
                                _this.certificateURL = _this.inspectors.find(function (x) { return x.id === _this.selectedInspectorID; })
                                    ? _this.inspectors.find(function (x) { return x.id === _this.selectedInspectorID; }).stateCertificateImage
                                    : "";
                                _this.nationalNICETNumber = _this.inspectors.find(function (x) { return x.id === _this.selectedInspectorID; })
                                    ? _this.inspectors.find(function (x) { return x.id === _this.selectedInspectorID; }).nationalCertificate
                                    : "";
                                _this.otherCert = _this.inspectors.find(function (x) { return x.id === _this.selectedInspectorID; })
                                    ? _this.inspectors.find(function (x) { return x.id === _this.selectedInspectorID; }).otherCertificate
                                    : "";
                                _this.isBlocked = _this.inspectors.find(function (x) { return x.id === _this.selectedInspectorID; })
                                    ? _this.inspectors.find(function (x) { return x.id === _this.selectedInspectorID; }).isBlocked
                                    : false;
                                _this.blockedByAuthorityName = _this.inspectors.find(function (x) { return x.id === _this.selectedInspectorID; })
                                    ? _this.inspectors.find(function (x) { return x.id === _this.selectedInspectorID; }).blockedByAuthorityName
                                    : "";
                                _this.blockedByAuthorityEmail = _this.inspectors.find(function (x) { return x.id === _this.selectedInspectorID; })
                                    ? _this.inspectors.find(function (x) { return x.id === _this.selectedInspectorID; }).blockedByAuthorityEmail
                                    : "";
                                _this.inspectorType = _this.inspectors.find(function (x) { return x.id === _this.selectedInspectorID; })
                                    ? _this.inspectors.find(function (x) { return x.id === _this.selectedInspectorID; }).type
                                    : "";
                                _this.setOtherCert(_this.otherCert);
                            }
                        }));
                    }
                    // if (this.userRole !== 'ROLE_INSPECTOR') {
                    //   this.subscriptions.push(this.authorityService.getInspectionCompanyList().subscribe((res) => {
                    //     if (res) {
                    //       this.inspectionCompanies = res;
                    //       this.inspectionCompanies.unshift({ id: -1, data: 'Select' });
                    //       this.selectedInspectorID = -1;
                    //       this.inspectionResult.inspectedById = -1;
                    //       this.inspectionResult.inspectedByCertInspectorId = -1;
                    //     }
                    //   }));
                    // }
                    return forkJoin([questions, questionVersion, authSettings]);
                }))
                    .subscribe(function (res) {
                    _this.loggerService.pushToServer({
                        Line: 'enter-inspection.component.ts: 503',
                        API: "/propertyDetails/" + _this.propertyId,
                        Response: res
                    });
                    if (res && res[1]) {
                        if (res[1]["asset_type_version"] &&
                            res[1]["asset_type_version"].id) {
                            _this.inspectionResult.questionModelVersion =
                                res[1]["asset_type_version"].id;
                        }
                        if (res[1]["jurisdiction_model_version"] &&
                            res[1]["jurisdiction_model_version"].id) {
                            _this.inspectionResult.jurisdictionModelVersion =
                                res[1]["jurisdiction_model_version"].id;
                        }
                        if (res[1]["footer_model_version"] &&
                            res[1]["footer_model_version"].id) {
                            _this.inspectionResult.authorityMappedAttributesModelVersion =
                                res[1]["footer_model_version"].id;
                        }
                        if (res && res[2]) {
                            _this.inspectionDataforSecondPage.authorityRemarks =
                                res[2]["remarks"];
                        }
                    }
                    if (res && res[0]) {
                        _this.inspectionService.setStandPipeRows(0);
                        _this.inspectionService.sePVRRows(null);
                        _this.inspectionService.setHoseCount(0);
                        _this.inspectionService.setHoseRows(null);
                        _this.questions = res[0].sort(function (a, b) {
                            return a.questions.sequence - b.questions.sequence;
                        });
                        _this.questions.forEach(function (question) {
                            if (question && question.questions.optionType === "Radio") {
                                question.questions.options =
                                    question.questions.options.sort(function (a, b) {
                                        return a.sequence - b.sequence;
                                    });
                            }
                            if (question && question.questions.subquestion) {
                                question.questions.subquestion.forEach(function (x) {
                                    if (x.optionType === "Radio") {
                                        x.options = x.options.sort(function (a, b) {
                                            return a.sequence - b.sequence;
                                        });
                                    }
                                });
                            }
                            if (question &&
                                question.questions.optionType === "Checkbox") {
                                question.questions.options =
                                    question.questions.options.sort(function (a, b) {
                                        return a.sequence - b.sequence;
                                    });
                            }
                            if (_this.assetTypeName === "Backflow") {
                                _this.questions = res[0].sort(function (a, b) {
                                    return a.questions.sequence - b.questions.sequence;
                                });
                                _this.questions.forEach(function (question) {
                                    if (question && question.questions) {
                                        _this.qtd[question.questions.questionId] =
                                            question.questions.answer;
                                        if (question.questions.answer &&
                                            question.questions.answer[0] === "N/A" &&
                                            question.questions.optionType === "Text" &&
                                            question.questions.options) {
                                            _this.qtd[question.questions.questionId + "checkbox"] = true;
                                            _this.sequence.push(question.questions.sequence + 1);
                                        }
                                    }
                                    if (question &&
                                        question.questions.optionType === "Checkbox") {
                                        question.questions.options =
                                            question.questions.options.sort(function (a, b) {
                                                return a.sequence - b.sequence;
                                            });
                                        if (question.questions.answer &&
                                            question.questions.answer.length > 0) {
                                            // tslint:disable-next-line: prefer-for-of
                                            for (var i = 0; i < question.questions.answer.length; i++) {
                                                _this.qtd[question.questions.questionId +
                                                    "*o*" +
                                                    question.questions.answer[i]] = true;
                                            }
                                        }
                                    }
                                });
                            }
                        });
                    }
                    else {
                        _this.questions = [];
                    }
                }));
                if (_this.userRole !== "ROLE_INSPECTOR") {
                    _this.subscriptions.push(_this.inspectionService
                        .getInspectionCompanyDetails(_this.propertyId)
                        .subscribe(function (res) {
                        if (res) {
                            var inspectionCompanies = [];
                            inspectionCompanies = res;
                            inspectionCompanies.unshift({ id: -1, data: "select" });
                            _this.inspectionCompanies = inspectionCompanies;
                            _this.selectedInspectorID = -1;
                            _this.inspectionResult.inspectedById =
                                _this.inspectionResult.inspectedById &&
                                    _this.inspectionResult.inspectedById !== -1
                                    ? _this.inspectionResult.inspectedById
                                    : -1;
                        }
                    }));
                }
                _this.selectedReport = _this.reports[0]["id"];
            }
            else if (_this.method == "inspectReport") {
                console.log("In Inspect Report");
                _this.subscriptions.push(_this.inspectionService.getAssetInfo(assetId).subscribe(function (resp) {
                    _this.loggerService.pushToServer({
                        Line: 'enter-inspection.component.ts: 650',
                        API: "/propertyAsset/" + assetId,
                        Response: resp
                    });
                    _this.inspectionDataforSecondPage.id = resp.id;
                    _this.inspectionDataforSecondPage.authorityRemarks =
                        resp.authorityRemarks;
                    _this.inspectionDataforSecondPage.inspectedDate =
                        resp.inspectedDate;
                    _this.inspectionDataforSecondPage.inspectionAttachment =
                        resp.inspectionAttachment;
                    _this.inspectionDataforSecondPage.inspectionRemarks =
                        resp.inspectionRemarks;
                    _this.inspectionDataforSecondPage.isInspectorCustomArea =
                        resp.isInspectorCustomArea;
                    _this.inspectionDataforSecondPage.systemStatus =
                        resp.systemStatus;
                    _this.inspectionResult.inspectedById = resp.inspectedById;
                    _this.inspectionResult.inspectedByCertInspectorId =
                        resp.inspectedByCertInspectorId;
                    _this.inspectionResult.certType = resp.certType;
                    _this.inspectionResult.certificateGiven = resp.certificateGiven;
                    _this.inspectionResult.isCentralMonitoring =
                        resp.isCentralMonitoring;
                    _this.inspectionResult.monitoringCompany =
                        resp.monitoringCompany;
                    _this.inspectionResult.manufacturer = resp.manufacturer;
                    _this.inspectionResult.modelNumber = resp.modelNumber;
                    _this.inspectionResult.questionModelVersion =
                        resp.questionModelVersion;
                    // TODO: Remove commented code
                    // if (+this.qmv > resp.questionModelVersion) {
                    //   this.inspectionResult.questionModelVersion = this.qmv;
                    //   // 2140 - bug fix
                    //   // this.useLatest = true;
                    // } else {
                    //   this.inspectionResult.questionModelVersion = resp.questionModelVersion;
                    //   // this.useLatest = false;
                    // }
                    _this.inspectionResult.jurisdictionModelVersion =
                        resp.jurisdictionModelVersion;
                    _this.inspectionResult.authorityMappedAttributesModelVersion =
                        resp.authorityMappedAttributesModelVersion;
                    if (resp.inspectionType === "SIMPLIFIED_REPORT") {
                        _this.selectedReport = "SIMPLIFIED_REPORT";
                    }
                    else {
                        _this.selectedReport = "STANDARD_REPORT";
                    }
                    if (resp) {
                        _this.changeHoodTypeToLastInspection(resp);
                    }
                    _this.inspectionResult.otherCertification =
                        resp.otherCertification;
                    //this.inspectionResult.propertyAsset.assetLocation = resp.propertyAsset.assetLocation;
                    //this.inspectionResult.propertyAsset.assetCoverage = resp.propertyAsset.assetCoverage;
                    /* deficiencies */
                    if (resp.inspectionDeficiency &&
                        resp.inspectionDeficiency.length > 0) {
                        _this.defOption = "Yes";
                        _this.inspectionResult.isDeficiencyFound = true;
                        var deficiency = JSON.parse(JSON.stringify(resp.inspectionDeficiency));
                        deficiency.forEach(function (x) {
                            if (x.dateCorrected) {
                                x.date = x.dateCorrected;
                            }
                        });
                        _this.deficiencies = deficiency;
                    }
                    else {
                        _this.defOption = "No";
                        _this.inspectionResult.isDeficiencyFound = false;
                    }
                    _this.monitoringOption = resp.isCentralMonitoring ? "Yes" : "No";
                    // this.inspectorType = resp.certType;
                    _this.certificationGiven = resp.certificateGiven;
                    _this.monitoringCompanyName = resp.monitoringCompany;
                    _this.panelManufacturer = resp.manufacturer;
                    _this.modelNumber = resp.modelNumber;
                    /* get inspectors data and populate selected inspectors details */
                    // if (this.userRole !== 'ROLE_INSPECTOR') {
                    //   this.subscriptions.push(this.inspectionService.getInspectionCompanyDetails(this.propertyId).subscribe((res) => {
                    //     if (res) {
                    //       let inspectors = [];
                    //       inspectors = res;
                    //       inspectors.unshift({ id: -1, data: 'select' });
                    //       this.inspectors = inspectors;
                    //       this.selectedInspectorID = resp.inspectedById;
                    //       // tslint:disable-next-line: no-shadowed-variable
                    //       this.subscriptions.push(this.inspectionService.getInspectorDetails(resp.inspectedById).subscribe((resp) => {
                    //         if (resp) {
                    //           this.contractorLicense = resp.licenseNumber;
                    //           this.inspectionDataforSecondPage.address = resp.address;
                    //           this.inspectionDataforSecondPage.name = resp.name;
                    //           this.inspectionDataforSecondPage.contact = resp.contact ?
                    //             resp.contact.replace(this.phoneRegex, '$1-$2-$3') : '';
                    //         }
                    //       }));
                    //     }
                    //   }));
                    // }
                    if (resp) {
                        _this.inspectionService
                            .getPropertyDetailsById(_this.propertyId)
                            .pipe(map(function (res) {
                            if (res) {
                                _this.property.name = res.propertyName;
                                _this.property.address = res.propertyAddress;
                                _this.property.emailAddress = res.propertyEmail;
                                _this.jurisdicationId = res.jurisdictionId;
                                if (+_this.pac !== 0) {
                                    _this.property.accessCode = res.propertyAccessCode;
                                }
                                else {
                                    _this.property.accessCode = "";
                                }
                                _this.property.juristrictionName = res.jurisdiction;
                                /* Owner Details*/
                                _this.owner.name = res.propertyOwner;
                                _this.owner.address = res.ownerAddress;
                                _this.owner.primaryContact = res.ownerContactNumber
                                    ? res.ownerContactNumber.replace(_this.phoneRegex, "$1-$2-$3")
                                    : "";
                                return res;
                            }
                        }), switchMap(function (property) {
                            _this.inspectionService
                                .getQuestionVersion(_this.id, +property.jurisdictionId)
                                .subscribe(function (x) {
                                _this.loggerService.pushToServer({
                                    Line: 'enter-inspection.component.ts: 790',
                                    API: "/question/version/" + _this.id + "/" + property.jurisdictionId,
                                    Response: x
                                });
                                if (x &&
                                    x["asset_type_version"] &&
                                    x["asset_type_version"].id) {
                                    _this.inspectionResult.questionModelVersion =
                                        x["asset_type_version"].id;
                                }
                                if (x["jurisdiction_model_version"] &&
                                    x["jurisdiction_model_version"].id) {
                                    _this.inspectionResult.jurisdictionModelVersion =
                                        x["jurisdiction_model_version"].id;
                                }
                                if (x["footer_model_version"] &&
                                    x["footer_model_version"].id) {
                                    _this.inspectionResult.authorityMappedAttributesModelVersion =
                                        x["footer_model_version"].id;
                                }
                            });
                            if (_this.userRole === "ROLE_INSPECTOR") {
                                _this.subscriptions.push(_this.inspectionService
                                    .getInspectorList(+property.jurisdictionId, +assetId)
                                    .subscribe(function (res) {
                                    if (res) {
                                        var inspectors = void 0;
                                        //const result
                                        inspectors = res;
                                        _this.inspectors = inspectors;
                                        inspectors.unshift({
                                            id: -1,
                                            firstName: "Select",
                                            middleName: "",
                                            lastName: "",
                                            isBlocked: false,
                                        });
                                        //this.inspectors = inspectors;
                                        var preSelectedInspector = inspectors.find(function (x) { return x.isSelected === true; });
                                        if (_this.isInspectorCertificateExpired(preSelectedInspector))
                                            _this.isInspectorExpired = true;
                                        _this.selectedInspectorID =
                                            preSelectedInspector &&
                                                !preSelectedInspector.isBlocked
                                                ? preSelectedInspector.id
                                                : -1;
                                        _this.inspectionResult.inspectedByCertInspectorId =
                                            _this.selectedInspectorID;
                                        //this.selectedInspectorID =   !this.inspectors.find((x) => x.id === + resp.inspectedByCertInspectorId).isBlocked
                                        // ? resp.inspectedByCertInspectorId :-1 ;
                                        //this.inspectionResult.inspectedByCertInspectorId =this.selectedInspectorID;
                                        // TEGFE-3047: certificate details and other fields were not visible when new report is filled.
                                        _this.inspectorphoneNum = _this.inspectors.find(function (x) { return x.id === +_this.selectedInspectorID; })
                                            ? _this.inspectors
                                                .find(function (x) {
                                                return x.id === _this.selectedInspectorID;
                                            })
                                                .phoneNumber.replace(_this.phoneRegex, "$1-$2-$3")
                                            : "";
                                        _this.stateCertificationNumber =
                                            _this.inspectors.find(function (x) { return x.id === _this.selectedInspectorID; })
                                                ? _this.inspectors.find(function (x) {
                                                    return x.id === _this.selectedInspectorID;
                                                }).stateCertificate
                                                : "";
                                        _this.additionalCertificateList = _this.inspectors.find(function (x) { return x.id === _this.selectedInspectorID && !x.isBlocked && !_this.isInspectorCertificateExpired(x); }) ? _this.additionalCertificateList = _this.inspectors.find(function (x) { return x.id === _this.selectedInspectorID; }).additionalCertificateDtoList : [];
                                        _this.certificateURL = _this.inspectors.find(function (x) { return x.id === _this.selectedInspectorID; })
                                            ? _this.inspectors.find(function (x) { return x.id === _this.selectedInspectorID; }).stateCertificateImage
                                            : "";
                                        _this.nationalNICETNumber =
                                            _this.inspectors.find(function (x) { return x.id === _this.selectedInspectorID; })
                                                ? _this.inspectors.find(function (x) {
                                                    return x.id === _this.selectedInspectorID;
                                                }).nationalCertificate
                                                : "";
                                        _this.otherCert = _this.inspectors.find(function (x) { return x.id === _this.selectedInspectorID; })
                                            ? _this.inspectors.find(function (x) { return x.id === _this.selectedInspectorID; }).otherCertificate
                                            : "";
                                        _this.isBlocked = _this.inspectors.find(function (x) { return x.id === _this.selectedInspectorID; })
                                            ? _this.inspectors.find(function (x) { return x.id === _this.selectedInspectorID; }).isBlocked
                                            : false;
                                        _this.blockedByAuthorityName =
                                            _this.inspectors.find(function (x) { return x.id === _this.selectedInspectorID; })
                                                ? _this.inspectors.find(function (x) {
                                                    return x.id === _this.selectedInspectorID;
                                                }).blockedByAuthorityName
                                                : "";
                                        _this.blockedByAuthorityEmail =
                                            _this.inspectors.find(function (x) { return x.id === _this.selectedInspectorID; })
                                                ? _this.inspectors.find(function (x) {
                                                    return x.id === _this.selectedInspectorID;
                                                }).blockedByAuthorityEmail
                                                : "";
                                        _this.inspectorType = _this.inspectors.find(function (x) { return x.id === _this.selectedInspectorID; })
                                            ? _this.inspectors.find(function (x) { return x.id === _this.selectedInspectorID; }).type
                                            : "";
                                        _this.setOtherCert(_this.otherCert);
                                        /*
                            this.inspectorphoneNum = this.inspectors.find((x) => x.id === + resp.inspectedByCertInspectorId) ?
                              this.inspectors.find((x) => x.id === resp.inspectedByCertInspectorId).
                                phoneNumber.replace(this.phoneRegex, '$1-$2-$3') : '';
                            this.stateCertificationNumber = this.inspectors.find((x) => x.id === resp.inspectedByCertInspectorId) ?
                              this.inspectors.find((x) => x.id === resp.inspectedByCertInspectorId).stateCertificate : '';
                            this.certificateURL = this.inspectors.find((x) => x.id === resp.inspectedByCertInspectorId) ?
                              this.inspectors.find((x) => x.id === resp.inspectedByCertInspectorId).stateCertificateImage : '';
                            this.nationalNICETNumber = this.inspectors.find((x) => x.id === resp.inspectedByCertInspectorId) ?
                              this.inspectors.find((x) => x.id === resp.inspectedByCertInspectorId).nationalCertificate : '';
                            this.otherCert = this.inspectors.find((x) => x.id === resp.inspectedByCertInspectorId) ?
                              this.inspectors.find((x) => x.id === resp.inspectedByCertInspectorId).otherCertificate : '';
      
                              this.isBlocked = this.inspectors.find((x) => x.id === this.selectedInspectorID) ?
                              this.inspectors.find((x) => x.id === this.selectedInspectorID).isBlocked : false;
                              this.blockedByAuthorityName = this.inspectors.find((x) => x.id === this.selectedInspectorID) ?
                              this.inspectors.find((x) => x.id === this.selectedInspectorID).blockedByAuthorityName : '';
                              this.blockedByAuthorityEmail = this.inspectors.find((x) => x.id === this.selectedInspectorID) ?
                              this.inspectors.find((x) => x.id === this.selectedInspectorID).blockedByAuthorityEmail : '';
                              this.inspectorType = this.inspectors.find((x) => x.id === this.selectedInspectorID) ?
                              this.inspectors.find((x) => x.id === this.selectedInspectorID).type : '';
                              */
                                    }
                                }));
                            }
                            if (_this.inspectionDataforSecondPage.id) {
                                console.log("in inspectionIdSecondPage");
                                //return this.inspectionService.getQuestionsByReportId(this.inspectionDataforSecondPage.id, +property.jurisdictionId);
                                return _this.inspectionService.getQuestions(_this.id, +property.jurisdictionId, assetId);
                            }
                            else {
                                console.log("not in inspectionIdSecondPage");
                                return _this.inspectionService.getQuestions(_this.id, +property.jurisdictionId, assetId);
                            }
                            // TODO: remove commented code
                            // if (this.useLatest) {
                            //   //alert('Report template is updated .Kindly edit & fill details in new template & submit');
                            // } else {
                            // }
                        }))
                            .subscribe(function (res) {
                            if (res) {
                                if (!_this.useLatest) {
                                    _this.questions = res.sort(function (a, b) {
                                        return a.questions.sequence - b.questions.sequence;
                                    });
                                    _this.questions.forEach(function (question) {
                                        if (question && question.questions) {
                                            _this.qtd[question.questions.questionId] =
                                                question.questions.answer;
                                            if (question.questions.answer &&
                                                question.questions.answer[0] === "N/A" &&
                                                question.questions.optionType === "Text" &&
                                                question.questions.options) {
                                                _this.qtd[question.questions.questionId + "checkbox"] =
                                                    question.questions.questionType !==
                                                        "Additional"
                                                        ? true
                                                        : false;
                                                _this.sequence.push(question.questions.sequence + 1);
                                            }
                                        }
                                        if (question.questions.optionType === "Table") {
                                            if (question.questions.answer &&
                                                question.questions.answer[0]) {
                                                var finalAnswer = question.questions.answer[0].split("~");
                                                for (var i = 0; i < finalAnswer.length; i++) {
                                                    _this.qtd[question.questions.questionId +
                                                        "~m~" +
                                                        i +
                                                        "~" +
                                                        finalAnswer.length] = finalAnswer[i];
                                                }
                                                question.questions.tableAnswers[0].unitsTested =
                                                    "";
                                                question.questions.tableAnswers[0].resultsAcceptable =
                                                    "";
                                            }
                                        }
                                        if (question.questions.questionType === "PRV") {
                                            if (question.questions.answer &&
                                                question.questions.answer[0]) {
                                                _this.inspectionDataforSecondPage.showPRV = true;
                                                var finalAnswer = question.questions.answer[0].split("~");
                                                if (_this.prvRows.length < finalAnswer.length) {
                                                    for (var k = 0; k < finalAnswer.length; k++) {
                                                        _this.prvRows.push({ id: k });
                                                    }
                                                }
                                                _this.inspectionService.setStandPipeRows(finalAnswer.length);
                                                for (var i = 0; i < finalAnswer.length; i++) {
                                                    _this.prvRows[i][question.questions.question] =
                                                        finalAnswer[i];
                                                }
                                            }
                                        }
                                        if (question.questions.questionType === "ClassII") {
                                            if (question.questions.answer &&
                                                question.questions.answer[0]) {
                                                _this.inspectionDataforSecondPage.showHose =
                                                    true;
                                                var finalAnswer = question.questions.answer[0].split("~");
                                                if (_this.hoseRows.length < finalAnswer.length) {
                                                    for (var k = 0; k < finalAnswer.length; k++) {
                                                        _this.hoseRows.push({ id: k });
                                                    }
                                                }
                                                _this.inspectionService.setHoseCount(finalAnswer.length);
                                                for (var i = 0; i < finalAnswer.length; i++) {
                                                    _this.hoseRows[i][question.questions.question] = finalAnswer[i];
                                                }
                                            }
                                        }
                                        if (question &&
                                            question.questions &&
                                            question.questions.images &&
                                            question.questions.images.length > 0) {
                                            var imagesForNextScreen_1 = [];
                                            question.questions.images.forEach(function (x) {
                                                imagesForNextScreen_1.push({
                                                    imageContent: x.imageContent,
                                                    imagePlacement: x.imagePlacement,
                                                    imageName: x.imageName,
                                                });
                                            });
                                            _this.images[question.questions.questionId] =
                                                imagesForNextScreen_1;
                                            if (question.questions.question ===
                                                "Pictures of Hydrant (front and back wide enough to show clearance)") {
                                                if (question.questions.images) {
                                                    if (question.questions.images.filter(function (x) { return x.imagePlacement === "Front"; }).length === 0) {
                                                        _this.noFrontImage = true;
                                                    }
                                                    if (question.questions.images.filter(function (x) { return x.imagePlacement === "Back"; }).length === 0) {
                                                        _this.noBackImage = true;
                                                    }
                                                }
                                            }
                                        }
                                        if (question &&
                                            question.questions &&
                                            question.questions.subquestion) {
                                            question.questions.subquestion.forEach(function (x) {
                                                if (x && x.images && x.images.length > 0) {
                                                    var imagesForNextScreen_2 = [];
                                                    x.images.forEach(function (y) {
                                                        imagesForNextScreen_2.push({
                                                            imageContent: y.imageContent,
                                                            imagePlacement: y.imagePlacement,
                                                            imageName: y.imageName,
                                                        });
                                                    });
                                                    _this.images[x.questionId] =
                                                        imagesForNextScreen_2;
                                                }
                                                _this.qtd[x.questionId] = x.answer
                                                    ? x.answer[0]
                                                    : null;
                                                if (x.optionType === "Radio") {
                                                    x.options = x.options.sort(function (a, b) {
                                                        return a.sequence - b.sequence;
                                                    });
                                                }
                                                if (x.optionType === "Checkbox") {
                                                    if (x.answer && x.answer.length > 0) {
                                                        // tslint:disable-next-line: prefer-for-of
                                                        for (var i = 0; i < x.answer.length; i++) {
                                                            _this.qtd[x.questionId + "*o*" + x.answer[i]] = true;
                                                        }
                                                    }
                                                }
                                                if (x.answer &&
                                                    x.answer[0] === "N/A" &&
                                                    x.optionType === "Text" &&
                                                    x.options) {
                                                    _this.qtd[x.questionId + "checkbox"] = true;
                                                }
                                                if (x.subquestion && x.subquestion.length > 0) {
                                                    if (x.subquestion[0].optionType === "Text") {
                                                        if (x.subquestion[0].answer) {
                                                            _this.qtd[x.subquestion[0].questionId] =
                                                                x.subquestion[0].answer[0];
                                                        }
                                                    }
                                                    else {
                                                        if (x.subquestion[0].answer &&
                                                            x.subquestion[0].answer.length > 0) {
                                                            // tslint:disable-next-line: prefer-for-of
                                                            for (var i = 0; i < x.subquestion[0].answer.length; i++) {
                                                                _this.qtd[x.subquestion[0].questionId +
                                                                    "*o*" +
                                                                    x.subquestion[0].answer[i]] = true;
                                                            }
                                                        }
                                                    }
                                                }
                                            });
                                        }
                                        if ((question &&
                                            question.questions.optionType === "Radio") ||
                                            question.questions.questionType ===
                                                "Test Results Acceptable") {
                                            if (question.questions.answer) {
                                                console.log(_this.assetTypeName, _this.inspectionResult.questionModelVersion);
                                                if (_this.assetTypeName === 'Fire and Smoke Damper' && _this.inspectionResult.questionModelVersion === 5) {
                                                    _this.qtd[question.questions.questionId] =
                                                        question.questions.answer;
                                                }
                                                else {
                                                    _this.qtd[question.questions.questionId] =
                                                        question.questions.answer[0];
                                                }
                                            }
                                            question.questions.options =
                                                question.questions.options.sort(function (a, b) {
                                                    return a.sequence - b.sequence;
                                                });
                                            if (question && question.questions.subquestion) {
                                                question.questions.subquestion.forEach(function (x) {
                                                    _this.qtd[x.questionId] = x.answer
                                                        ? x.answer[0]
                                                        : null;
                                                    if (x.optionType === "Radio") {
                                                        x.options = x.options.sort(function (a, b) {
                                                            return a.sequence - b.sequence;
                                                        });
                                                    }
                                                });
                                            }
                                        }
                                        if (question &&
                                            question.questions.optionType === "Checkbox") {
                                            question.questions.options =
                                                question.questions.options.sort(function (a, b) {
                                                    return a.sequence - b.sequence;
                                                });
                                            if (question.questions.answer &&
                                                question.questions.answer.length > 0) {
                                                // tslint:disable-next-line: prefer-for-of
                                                for (var i = 0; i < question.questions.answer.length; i++) {
                                                    _this.qtd[question.questions.questionId +
                                                        "*o*" +
                                                        question.questions.answer[i]] = true;
                                                }
                                            }
                                        }
                                    });
                                    _this.inspectionService.sePVRRows(_this.prvRows);
                                    _this.inspectionService.setHoseRows(_this.hoseRows);
                                    _this.inspectionDataforSecondPage.sequence =
                                        _this.sequence;
                                    _this.inspectionDataforSecondPage.noFrontImage =
                                        _this.noFrontImage;
                                    _this.inspectionDataforSecondPage.noBackImage =
                                        _this.noBackImage;
                                }
                                else {
                                    _this.inspectionService.setStandPipeRows(0);
                                    _this.inspectionService.sePVRRows(null);
                                    _this.inspectionService.setHoseCount(0);
                                    _this.inspectionService.setHoseRows(null);
                                    _this.questions = res.sort(function (a, b) {
                                        return a.questions.sequence - b.questions.sequence;
                                    });
                                    _this.questions.forEach(function (question) {
                                        if (question &&
                                            question.questions.optionType === "Radio") {
                                            question.questions.options =
                                                question.questions.options.sort(function (a, b) {
                                                    return a.sequence - b.sequence;
                                                });
                                        }
                                        if (question && question.questions.subquestion) {
                                            question.questions.subquestion.forEach(function (x) {
                                                if (x.optionType === "Radio") {
                                                    x.options = x.options.sort(function (a, b) {
                                                        return a.sequence - b.sequence;
                                                    });
                                                }
                                            });
                                        }
                                        if (question &&
                                            question.questions.optionType === "Checkbox") {
                                            question.questions.options =
                                                question.questions.options.sort(function (a, b) {
                                                    return a.sequence - b.sequence;
                                                });
                                        }
                                    });
                                }
                            }
                            else {
                                _this.questions = [];
                            }
                        });
                    }
                }, function (er) {
                    if (er &&
                        er.error &&
                        er.error.message &&
                        er.error.message ===
                            "Logged in user is not authorised to see inspection.") {
                        alert("Logged in user is not authorised to see inspection.");
                        _this.redirectDashboard();
                    }
                }));
            }
            else {
                _this.subscriptions.push(_this.inspectionService
                    .getInspectionReportIdbyAssetId(assetId)
                    .subscribe(function (resp) {
                    _this.loggerService.pushToServer({
                        Line: 'enter-inspection.component.ts: 1338',
                        API: "/inspection/propertyAsset/" + assetId,
                        Response: resp
                    });
                    _this.inspectionDataforSecondPage.id = resp.id;
                    _this.inspectionDataforSecondPage.authorityRemarks =
                        resp.authorityRemarks;
                    _this.inspectionDataforSecondPage.inspectedDate =
                        resp.inspectedDate;
                    _this.inspectionDataforSecondPage.inspectionAttachment =
                        resp.inspectionAttachment;
                    _this.inspectionDataforSecondPage.inspectionRemarks =
                        resp.inspectionRemarks;
                    _this.inspectionDataforSecondPage.isInspectorCustomArea =
                        resp.isInspectorCustomArea;
                    _this.inspectionDataforSecondPage.systemStatus =
                        resp.systemStatus;
                    _this.inspectionResult.inspectedById = resp.inspectedById;
                    _this.inspectionResult.inspectedByCertInspectorId =
                        resp.inspectedByCertInspectorId;
                    _this.inspectionResult.certType = resp.certType;
                    _this.inspectionResult.certificateGiven =
                        resp.certificateGiven;
                    _this.inspectionResult.isCentralMonitoring =
                        resp.isCentralMonitoring;
                    _this.inspectionResult.monitoringCompany =
                        resp.monitoringCompany;
                    _this.inspectionResult.manufacturer = resp.manufacturer;
                    _this.inspectionResult.modelNumber = resp.modelNumber;
                    _this.inspectionResult.questionModelVersion =
                        resp.questionModelVersion;
                    // TODO: Remove commented code
                    // if (+this.qmv > resp.questionModelVersion) {
                    //   this.inspectionResult.questionModelVersion = this.qmv;
                    //   // 2140 - bug fix
                    //   // this.useLatest = true;
                    // } else {
                    //   this.inspectionResult.questionModelVersion = resp.questionModelVersion;
                    //   // this.useLatest = false;
                    // }
                    _this.inspectionResult.jurisdictionModelVersion =
                        resp.jurisdictionModelVersion;
                    _this.inspectionResult.authorityMappedAttributesModelVersion =
                        resp.authorityMappedAttributesModelVersion;
                    if (resp.inspectionType === "SIMPLIFIED_REPORT") {
                        _this.selectedReport = "SIMPLIFIED_REPORT";
                    }
                    else {
                        _this.selectedReport = "STANDARD_REPORT";
                    }
                    if (resp.hoodType &&
                        resp.hoodType !== "Kitchen/Range Hood" &&
                        resp.hoodType !== "Paint Spray Booth" &&
                        resp.hoodType !== "Fume Hood") {
                        _this.hoodSelected = "Others";
                        _this.othersText = resp.otherHoodType;
                    }
                    else {
                        _this.hoodSelected = resp.hoodType;
                    }
                    _this.inspectionResult.otherCertification =
                        resp.otherCertification;
                    _this.inspectionResult.propertyAsset.assetLocation =
                        resp.propertyAsset.assetLocation;
                    _this.inspectionResult.propertyAsset.assetCoverage =
                        resp.propertyAsset.assetCoverage;
                    /* deficiencies */
                    if (resp.inspectionDeficiency &&
                        resp.inspectionDeficiency.length > 0) {
                        _this.defOption = "Yes";
                        _this.inspectionResult.isDeficiencyFound = true;
                        var deficiency = JSON.parse(JSON.stringify(resp.inspectionDeficiency));
                        deficiency.forEach(function (x) {
                            if (x.dateCorrected) {
                                x.date = x.dateCorrected;
                            }
                        });
                        _this.deficiencies = deficiency;
                    }
                    else {
                        _this.defOption = "No";
                        _this.inspectionResult.isDeficiencyFound = false;
                    }
                    _this.monitoringOption = resp.isCentralMonitoring
                        ? "Yes"
                        : "No";
                    // this.inspectorType = resp.certType;
                    _this.certificationGiven = resp.certificateGiven;
                    _this.monitoringCompanyName = resp.monitoringCompany;
                    _this.panelManufacturer = resp.manufacturer;
                    _this.modelNumber = resp.modelNumber;
                    /* get inspectors data and populate selected inspectors details */
                    // if (this.userRole !== 'ROLE_INSPECTOR') {
                    //   this.subscriptions.push(this.inspectionService.getInspectionCompanyDetails(this.propertyId).subscribe((res) => {
                    //     if (res) {
                    //       let inspectors = [];
                    //       inspectors = res;
                    //       inspectors.unshift({ id: -1, data: 'select' });
                    //       this.inspectors = inspectors;
                    //       this.selectedInspectorID = resp.inspectedById;
                    //       // tslint:disable-next-line: no-shadowed-variable
                    //       this.subscriptions.push(this.inspectionService.getInspectorDetails(resp.inspectedById).subscribe((resp) => {
                    //         if (resp) {
                    //           this.contractorLicense = resp.licenseNumber;
                    //           this.inspectionDataforSecondPage.address = resp.address;
                    //           this.inspectionDataforSecondPage.name = resp.name;
                    //           this.inspectionDataforSecondPage.contact = resp.contact ?
                    //             resp.contact.replace(this.phoneRegex, '$1-$2-$3') : '';
                    //         }
                    //       }));
                    //     }
                    //   }));
                    // }
                    if (resp) {
                        _this.inspectionService
                            .getPropertyDetailsById(_this.propertyId)
                            .pipe(map(function (res) {
                            if (res) {
                                _this.property.name = res.propertyName;
                                _this.property.address = res.propertyAddress;
                                _this.property.emailAddress = res.propertyEmail;
                                _this.jurisdicationId = res.jurisdictionId;
                                if (+_this.pac !== 0) {
                                    _this.property.accessCode = res.propertyAccessCode;
                                }
                                else {
                                    _this.property.accessCode = "";
                                }
                                _this.property.juristrictionName = res.jurisdiction;
                                /* Owner Details*/
                                _this.owner.name = res.propertyOwner;
                                _this.owner.address = res.ownerAddress;
                                _this.owner.primaryContact = res.ownerContactNumber
                                    ? res.ownerContactNumber.replace(_this.phoneRegex, "$1-$2-$3")
                                    : "";
                                return res;
                            }
                        }), switchMap(function (property) {
                            if (_this.userRole === "ROLE_INSPECTOR") {
                                _this.subscriptions.push(_this.inspectionService
                                    .getInspectorList(+property.jurisdictionId, +assetId)
                                    .subscribe(function (res) {
                                    if (res) {
                                        var inspectors = void 0;
                                        inspectors = res;
                                        inspectors.unshift({
                                            id: -1,
                                            firstName: "Select",
                                            middleName: "",
                                            lastName: "",
                                            isBlocked: false,
                                        });
                                        _this.inspectors = inspectors;
                                        _this.selectedInspectorID =
                                            !_this.inspectors.find(function (x) {
                                                return x.id ===
                                                    +resp.inspectedByCertInspectorId;
                                            }).isBlocked
                                                ? resp.inspectedByCertInspectorId
                                                : -1;
                                        _this.inspectionResult.inspectedByCertInspectorId =
                                            _this.selectedInspectorID;
                                        _this.inspectorphoneNum =
                                            _this.inspectors.find(function (x) {
                                                return x.id ===
                                                    +resp.inspectedByCertInspectorId;
                                            })
                                                ? _this.inspectors
                                                    .find(function (x) {
                                                    return x.id ===
                                                        resp.inspectedByCertInspectorId;
                                                })
                                                    .phoneNumber.replace(_this.phoneRegex, "$1-$2-$3")
                                                : "";
                                        _this.stateCertificationNumber =
                                            _this.inspectors.find(function (x) {
                                                return x.id ===
                                                    resp.inspectedByCertInspectorId;
                                            })
                                                ? _this.inspectors.find(function (x) {
                                                    return x.id ===
                                                        resp.inspectedByCertInspectorId;
                                                }).stateCertificate
                                                : "";
                                        _this.additionalCertificateList = _this.inspectors.find(function (x) { return x.id === _this.selectedInspectorID && !x.isBlocked && !_this.isInspectorCertificateExpired(x); }) ? _this.additionalCertificateList = _this.inspectors.find(function (x) { return x.id === _this.selectedInspectorID; }).additionalCertificateDtoList : [];
                                        _this.certificateURL = _this.inspectors.find(function (x) {
                                            return x.id === resp.inspectedByCertInspectorId;
                                        })
                                            ? _this.inspectors.find(function (x) {
                                                return x.id ===
                                                    resp.inspectedByCertInspectorId;
                                            }).stateCertificateImage
                                            : "";
                                        _this.nationalNICETNumber =
                                            _this.inspectors.find(function (x) {
                                                return x.id ===
                                                    resp.inspectedByCertInspectorId;
                                            })
                                                ? _this.inspectors.find(function (x) {
                                                    return x.id ===
                                                        resp.inspectedByCertInspectorId;
                                                }).nationalCertificate
                                                : "";
                                        _this.otherCert = _this.inspectors.find(function (x) {
                                            return x.id === resp.inspectedByCertInspectorId;
                                        })
                                            ? _this.inspectors.find(function (x) {
                                                return x.id ===
                                                    resp.inspectedByCertInspectorId;
                                            }).otherCertificate
                                            : "";
                                        _this.isBlocked = _this.inspectors.find(function (x) { return x.id === _this.selectedInspectorID; })
                                            ? _this.inspectors.find(function (x) {
                                                return x.id === _this.selectedInspectorID;
                                            }).isBlocked
                                            : false;
                                        _this.blockedByAuthorityName =
                                            _this.inspectors.find(function (x) { return x.id === _this.selectedInspectorID; })
                                                ? _this.inspectors.find(function (x) {
                                                    return x.id === _this.selectedInspectorID;
                                                }).blockedByAuthorityName
                                                : "";
                                        _this.blockedByAuthorityEmail =
                                            _this.inspectors.find(function (x) { return x.id === _this.selectedInspectorID; })
                                                ? _this.inspectors.find(function (x) {
                                                    return x.id === _this.selectedInspectorID;
                                                }).blockedByAuthorityEmail
                                                : "";
                                        _this.inspectorType = _this.inspectors.find(function (x) { return x.id === _this.selectedInspectorID; })
                                            ? _this.inspectors.find(function (x) {
                                                return x.id === _this.selectedInspectorID;
                                            }).type
                                            : "";
                                    }
                                }));
                            }
                            if (_this.inspectionDataforSecondPage.id) {
                                return _this.inspectionService.getQuestionsByReportId(_this.inspectionDataforSecondPage.id, +property.jurisdictionId);
                            }
                            else {
                                return _this.inspectionService.getQuestions(_this.id, +property.jurisdictionId, assetId);
                            }
                            // TODO: remove commented code
                            // if (this.useLatest) {
                            //   //alert('Report template is updated .Kindly edit & fill details in new template & submit');
                            // } else {
                            // }
                        }))
                            .subscribe(function (res) {
                            if (res) {
                                if (!_this.useLatest) {
                                    _this.questions = res.sort(function (a, b) {
                                        return (a.questions.sequence - b.questions.sequence);
                                    });
                                    _this.questions.forEach(function (question) {
                                        if (question && question.questions) {
                                            _this.qtd[question.questions.questionId] =
                                                question.questions.answer;
                                            if (question.questions.answer &&
                                                question.questions.answer[0] === "N/A" &&
                                                question.questions.optionType === "Text" &&
                                                question.questions.options) {
                                                _this.qtd[question.questions.questionId + "checkbox"] =
                                                    question.questions.questionType !==
                                                        "Additional"
                                                        ? true
                                                        : false;
                                                _this.sequence.push(question.questions.sequence + 1);
                                            }
                                        }
                                        if (question.questions.optionType === "Table") {
                                            if (question.questions.answer &&
                                                question.questions.answer[0]) {
                                                var finalAnswer = question.questions.answer[0].split("~");
                                                for (var i = 0; i < finalAnswer.length; i++) {
                                                    _this.qtd[question.questions.questionId +
                                                        "~m~" +
                                                        i +
                                                        "~" +
                                                        finalAnswer.length] = finalAnswer[i];
                                                }
                                            }
                                        }
                                        if (question.questions.questionType === "PRV") {
                                            if (question.questions.answer &&
                                                question.questions.answer[0]) {
                                                _this.inspectionDataforSecondPage.showPRV =
                                                    true;
                                                var finalAnswer = question.questions.answer[0].split("~");
                                                if (_this.prvRows.length < finalAnswer.length) {
                                                    for (var k = 0; k < finalAnswer.length; k++) {
                                                        _this.prvRows.push({ id: k });
                                                    }
                                                }
                                                _this.inspectionService.setStandPipeRows(finalAnswer.length);
                                                for (var i = 0; i < finalAnswer.length; i++) {
                                                    _this.prvRows[i][question.questions.question] = finalAnswer[i];
                                                }
                                            }
                                        }
                                        if (question.questions.questionType === "ClassII") {
                                            if (question.questions.answer &&
                                                question.questions.answer[0]) {
                                                _this.inspectionDataforSecondPage.showHose =
                                                    true;
                                                var finalAnswer = question.questions.answer[0].split("~");
                                                if (_this.hoseRows.length < finalAnswer.length) {
                                                    for (var k = 0; k < finalAnswer.length; k++) {
                                                        _this.hoseRows.push({ id: k });
                                                    }
                                                }
                                                _this.inspectionService.setHoseCount(finalAnswer.length);
                                                for (var i = 0; i < finalAnswer.length; i++) {
                                                    _this.hoseRows[i][question.questions.question] = finalAnswer[i];
                                                }
                                            }
                                        }
                                        if (question &&
                                            question.questions &&
                                            question.questions.images &&
                                            question.questions.images.length > 0) {
                                            var imagesForNextScreen_3 = [];
                                            question.questions.images.forEach(function (x) {
                                                imagesForNextScreen_3.push({
                                                    imageContent: x.imageContent,
                                                    imagePlacement: x.imagePlacement,
                                                    imageName: x.imageName,
                                                });
                                            });
                                            _this.images[question.questions.questionId] =
                                                imagesForNextScreen_3;
                                            if (question.questions.question ===
                                                "Pictures of Hydrant (front and back wide enough to show clearance)") {
                                                if (question.questions.images) {
                                                    if (question.questions.images.filter(function (x) { return x.imagePlacement === "Front"; }).length === 0) {
                                                        _this.noFrontImage = true;
                                                    }
                                                    if (question.questions.images.filter(function (x) { return x.imagePlacement === "Back"; }).length === 0) {
                                                        _this.noBackImage = true;
                                                    }
                                                }
                                            }
                                        }
                                        if (question &&
                                            question.questions &&
                                            question.questions.subquestion) {
                                            question.questions.subquestion.forEach(function (x) {
                                                if (x && x.images && x.images.length > 0) {
                                                    var imagesForNextScreen_4 = [];
                                                    x.images.forEach(function (y) {
                                                        imagesForNextScreen_4.push({
                                                            imageContent: y.imageContent,
                                                            imagePlacement: y.imagePlacement,
                                                            imageName: y.imageName,
                                                        });
                                                    });
                                                    _this.images[x.questionId] =
                                                        imagesForNextScreen_4;
                                                }
                                                _this.qtd[x.questionId] = x.answer
                                                    ? x.answer[0]
                                                    : null;
                                                if (x.optionType === "Radio") {
                                                    x.options = x.options.sort(function (a, b) {
                                                        return a.sequence - b.sequence;
                                                    });
                                                }
                                                if (x.optionType === "Checkbox") {
                                                    if (x.answer && x.answer.length > 0) {
                                                        // tslint:disable-next-line: prefer-for-of
                                                        for (var i = 0; i < x.answer.length; i++) {
                                                            _this.qtd[x.questionId + "*o*" + x.answer[i]] = true;
                                                        }
                                                    }
                                                }
                                                if (x.answer &&
                                                    x.answer[0] === "N/A" &&
                                                    x.optionType === "Text" &&
                                                    x.options) {
                                                    _this.qtd[x.questionId + "checkbox"] = true;
                                                }
                                                if (x.subquestion &&
                                                    x.subquestion.length > 0) {
                                                    if (x.subquestion[0].optionType === "Text") {
                                                        if (x.subquestion[0].answer) {
                                                            _this.qtd[x.subquestion[0].questionId] =
                                                                x.subquestion[0].answer[0];
                                                        }
                                                    }
                                                    else {
                                                        if (x.subquestion[0].answer &&
                                                            x.subquestion[0].answer.length > 0) {
                                                            // tslint:disable-next-line: prefer-for-of
                                                            for (var i = 0; i < x.subquestion[0].answer.length; i++) {
                                                                _this.qtd[x.subquestion[0].questionId +
                                                                    "*o*" +
                                                                    x.subquestion[0].answer[i]] = true;
                                                            }
                                                        }
                                                    }
                                                }
                                            });
                                        }
                                        if ((question &&
                                            question.questions.optionType === "Radio") ||
                                            question.questions.questionType ===
                                                "Test Results Acceptable") {
                                            if (question.questions.answer) {
                                                if (_this.assetTypeName === 'Fire and Smoke Damper' && _this.inspectionResult.questionModelVersion > 4) {
                                                    _this.qtd[question.questions.questionId] = question.questions.questionType === "Additional"
                                                        ? question.questions.answer[0] :
                                                        question.questions.answer;
                                                }
                                                else {
                                                    _this.qtd[question.questions.questionId] =
                                                        question.questions.answer[0];
                                                }
                                            }
                                            question.questions.options =
                                                question.questions.options.sort(function (a, b) {
                                                    return a.sequence - b.sequence;
                                                });
                                            if (question &&
                                                question.questions.subquestion) {
                                                question.questions.subquestion.forEach(function (x) {
                                                    _this.qtd[x.questionId] = x.answer
                                                        ? x.answer[0]
                                                        : null;
                                                    if (x.optionType === "Radio") {
                                                        x.options = x.options.sort(function (a, b) {
                                                            return a.sequence - b.sequence;
                                                        });
                                                    }
                                                });
                                            }
                                        }
                                        if (question &&
                                            question.questions.optionType === "Checkbox") {
                                            question.questions.options =
                                                question.questions.options.sort(function (a, b) {
                                                    return a.sequence - b.sequence;
                                                });
                                            if (question.questions.answer &&
                                                question.questions.answer.length > 0) {
                                                // tslint:disable-next-line: prefer-for-of
                                                for (var i = 0; i < question.questions.answer.length; i++) {
                                                    _this.qtd[question.questions.questionId +
                                                        "*o*" +
                                                        question.questions.answer[i]] = true;
                                                }
                                            }
                                        }
                                    });
                                    _this.inspectionService.sePVRRows(_this.prvRows);
                                    _this.inspectionService.setHoseRows(_this.hoseRows);
                                    _this.inspectionDataforSecondPage.sequence =
                                        _this.sequence;
                                    _this.inspectionDataforSecondPage.noFrontImage =
                                        _this.noFrontImage;
                                    _this.inspectionDataforSecondPage.noBackImage =
                                        _this.noBackImage;
                                }
                                else {
                                    _this.inspectionService.setStandPipeRows(0);
                                    _this.inspectionService.sePVRRows(null);
                                    _this.inspectionService.setHoseCount(0);
                                    _this.inspectionService.setHoseRows(null);
                                    _this.questions = res.sort(function (a, b) {
                                        return (a.questions.sequence - b.questions.sequence);
                                    });
                                    _this.questions.forEach(function (question) {
                                        if (question &&
                                            question.questions.optionType === "Radio") {
                                            question.questions.options =
                                                question.questions.options.sort(function (a, b) {
                                                    return a.sequence - b.sequence;
                                                });
                                        }
                                        if (question && question.questions.subquestion) {
                                            question.questions.subquestion.forEach(function (x) {
                                                if (x.optionType === "Radio") {
                                                    x.options = x.options.sort(function (a, b) {
                                                        return a.sequence - b.sequence;
                                                    });
                                                }
                                            });
                                        }
                                        if (question &&
                                            question.questions.optionType === "Checkbox") {
                                            question.questions.options =
                                                question.questions.options.sort(function (a, b) {
                                                    return a.sequence - b.sequence;
                                                });
                                        }
                                    });
                                }
                            }
                            else {
                                _this.questions = [];
                            }
                        });
                    }
                }, function (er) {
                    if (er &&
                        er.error &&
                        er.error.message &&
                        er.error.message ===
                            "Logged in user is not authorised to see inspection.") {
                        alert("Logged in user is not authorised to see inspection.");
                        _this.redirectDashboard();
                    }
                }));
            }
            if (_this.propertyId) {
                _this.subscriptions.push(_this.inspectionService
                    .getPropertyDetailsById(_this.propertyId)
                    .subscribe(function (res) {
                    if (res) {
                        _this.property.name = res.propertyName;
                        _this.property.address = res.propertyAddress;
                        _this.property.emailAddress = res.propertyEmail;
                        _this.jurisdicationId = res.jurisdictionId;
                        if (+_this.pac !== 0) {
                            _this.property.accessCode = res.propertyAccessCode;
                        }
                        else {
                            _this.property.accessCode = "";
                        }
                        _this.property.juristrictionName = res.jurisdiction;
                        /* Owner Details*/
                        _this.owner.name = res.propertyOwner;
                        _this.owner.address = res.ownerAddress;
                        _this.owner.primaryContact = res.ownerContactNumber
                            ? res.ownerContactNumber.replace(_this.phoneRegex, "$1-$2-$3")
                            : "";
                    }
                }));
                _this.subscriptions.push(_this.inspectionService
                    .getManagerDetails(_this.propertyId)
                    .subscribe(function (res) {
                    if (res) {
                        if (!res.firstName) {
                            res.firstName = "";
                        }
                        if (!res.lastName) {
                            res.lastName = "";
                        }
                        _this.property.manager.name =
                            res.firstName + " " + res.lastName;
                        _this.property.manager.number = res.phoneNum
                            ? res.phoneNum.replace(_this.phoneRegex, "$1-$2-$3")
                            : "";
                        _this.propertyEmail = res.email;
                    }
                }));
                if (_this.userRole !== "ROLE_SITE_ADMIN") {
                    _this.subscriptions.push(_this.inspectionService.getUserDetails().subscribe(function (res) {
                        if (res) {
                            _this.contractorLicense = res.licenseNumber;
                            _this.inspectionDataforSecondPage.address = res.address;
                            _this.inspectionDataforSecondPage.name = res.name;
                            _this.inspectionDataforSecondPage.contact = res.contact
                                ? res.contact.replace(_this.phoneRegex, "$1-$2-$3")
                                : "";
                        }
                    }));
                }
                _this.subscriptions.push(_this.inspectionService.getAssetInfo(assetId).subscribe(function (res) {
                    if (res) {
                        _this.systemLocation = res.assetLocation;
                        _this.coverageArea = res.assetCoverage;
                        _this.inspectionFrequency = res.assetInspectionFrequency;
                        _this.propertyMarker =
                            _this.utilityService.setPropertyAndSystemOnMap(null, new Array(res));
                        _this.changeHoodTypeToLastInspection(res);
                    }
                }));
            }
        }));
    };
    EnterInspectionComponent.prototype.changeHoodTypeToLastInspection = function (resp) {
        var _this = this;
        this.subscriptions.push(this.inspectionService.getAssetHoodType(resp.id).subscribe(function (res) {
            if (resp.assetTypeId == 5) {
                var hd = res.hoodType;
                if (hd && hd !== 'Kitchen/Range Hood' && hd !== 'Paint Spray Booth' && hd !== 'Fume Hood') {
                    _this.hoodSelected = 'Others';
                    _this.othersText = res.otherHoodType;
                }
                else {
                    _this.hoodSelected = hd;
                }
            }
            else {
                console.log('this is called ');
                console.log(res);
                if (res && res.isCentralStationMonitoring) {
                    _this.setCentralMonitor("Yes");
                    _this.monitoringOption = "Yes";
                    if (res && res.monitoringCompanyName) {
                        _this.setMonitorCompany(res.monitoringCompanyName);
                        _this.monitoringCompanyName = res.monitoringCompanyName;
                    }
                    if (res && res.panelManufacturer) {
                        _this.setControlPanel(res.panelManufacturer);
                        _this.panelManufacturer = res.panelManufacturer;
                    }
                    if (res && res.modelNumber) {
                        _this.setModelNumber(res.modelNumber);
                        _this.modelNumber = res.modelNumber;
                    }
                }
            }
        }, function (error) {
            _this.loggerService.log(error);
        }));
    };
    EnterInspectionComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (this.userRole !== "ROLE_INSPECTOR") {
            setTimeout(function () {
                if (_this.userRole === "ROLE_SITE_ADMIN") {
                    _this.subscriptions.push(_this.authorityService
                        .getAdminInspectionCompanies()
                        .pipe(switchMap(function (res) {
                        if (res) {
                            _this.inspectionCompanies = res;
                            _this.inspectionCompanies.unshift({
                                id: -1,
                                data: "Select",
                            });
                            _this.selectedInspectorID = -1;
                            _this.inspectionResult.inspectedById =
                                _this.inspectionResult.inspectedById &&
                                    _this.inspectionResult.inspectedById !== -1
                                    ? _this.inspectionResult.inspectedById
                                    : -1;
                            _this.inspectionResult.inspectedByCertInspectorId =
                                _this.inspectionResult.inspectedByCertInspectorId &&
                                    _this.inspectionResult.inspectedByCertInspectorId !== -1
                                    ? _this.inspectionResult.inspectedByCertInspectorId
                                    : -1;
                            if (_this.inspectionResult.inspectedById !== -1) {
                                return _this.authorityService.getCertifiedInspectorsForACompany(_this.inspectionResult.inspectedById);
                            }
                            else {
                                return [];
                            }
                        }
                    }))
                        .subscribe(function (res) {
                        if (res && res.length > 0) {
                            var inspectors = [];
                            inspectors = res;
                            inspectors.unshift({
                                id: -1,
                                firstName: "Select",
                                middleName: "",
                                lastName: "",
                                isBlocked: false,
                            });
                            _this.inspectors = inspectors;
                            _this.inspectionResult.inspectedByCertInspectorId =
                                _this.inspectionResult.inspectedByCertInspectorId &&
                                    _this.inspectionResult.inspectedByCertInspectorId !== -1
                                    ? _this.inspectionResult.inspectedByCertInspectorId
                                    : -1;
                        }
                    }));
                }
                else {
                    _this.subscriptions.push(_this.authorityService
                        .getInspectionCompanyList()
                        .pipe(switchMap(function (res) {
                        if (res) {
                            _this.inspectionCompanies = res;
                            _this.inspectionCompanies.unshift({
                                id: -1,
                                data: "Select",
                            });
                            _this.selectedInspectorID = -1;
                            _this.inspectionResult.inspectedById =
                                _this.inspectionResult.inspectedById &&
                                    _this.inspectionResult.inspectedById !== -1
                                    ? _this.inspectionResult.inspectedById
                                    : -1;
                            _this.inspectionResult.inspectedByCertInspectorId =
                                _this.inspectionResult.inspectedByCertInspectorId &&
                                    _this.inspectionResult.inspectedByCertInspectorId !== -1
                                    ? _this.inspectionResult.inspectedByCertInspectorId
                                    : -1;
                            if (_this.inspectionResult.inspectedById !== -1) {
                                return _this.authorityService.getCertifiedInspectorsForACompany(_this.inspectionResult.inspectedById);
                            }
                            else {
                                return [];
                            }
                        }
                    }))
                        .subscribe(function (res) {
                        if (res && res.length > 0) {
                            var inspectors = [];
                            inspectors = res;
                            inspectors.unshift({
                                id: -1,
                                firstName: "Select",
                                middleName: "",
                                lastName: "",
                                isBlocked: false,
                            });
                            _this.inspectors = inspectors;
                            _this.inspectionResult.inspectedByCertInspectorId =
                                _this.inspectionResult.inspectedByCertInspectorId &&
                                    _this.inspectionResult.inspectedByCertInspectorId !== -1
                                    ? _this.inspectionResult.inspectedByCertInspectorId
                                    : -1;
                        }
                    }));
                }
            }, 5000);
        }
    };
    EnterInspectionComponent.prototype.onChange = function (event) {
        this.submitted = false;
        var inspectorId = event.target.value;
        this.inspectionResult.blockedInspector = false;
        this.isInspectorExpired = false;
        if (inspectorId !== -1) {
            var currentInspector = this.inspectors.find(function (x) { return x.id === +inspectorId; });
            var certExpiryDate = moment(currentInspector.stateCertExpiryDate, "MM-DD-YYYY");
            var nationalCertExpiryDate = moment(currentInspector.nationalCertExpiryDate, "MM-DD-YYYY");
            var otherCertExpiryDate = moment(currentInspector.otherCertExpiryDate, "MM-DD-YYYY");
            // if (
            //   !currentInspector.isBlocked &&
            //   ((currentInspector.stateCertificate.length >= 5 &&
            //     currentInspector.stateCertExpiryDate &&
            //     currentInspector.stateCertExpiryDate.length > 0 &&
            //     certExpiryDate >= moment()) ||
            //     (currentInspector.nationalCertificate.length >= 5 &&
            //       currentInspector.nationalCertExpiryDate &&
            //       currentInspector.nationalCertExpiryDate.length > 0 &&
            //       nationalCertExpiryDate >= moment()) ||
            //       (currentInspector.otherCertExpiryDate.length >= 5 &&
            //         currentInspector.otherCertExpiryDate &&
            //         currentInspector.otherCertExpiryDate.length > 0 &&
            //         otherCertExpiryDate >= moment())||
            //     (currentInspector.stateCertificate.length === 0 &&
            //       currentInspector.nationalCertificate.length === 0 &&
            //       currentInspector.otherCertificate.length === 0))
            if (!currentInspector.isBlocked &&
                !this.isInspectorCertificateExpired(currentInspector)) {
                this.inspectionResult.inspectedByCertInspectorId = +inspectorId;
                this.inspectorphoneNum = currentInspector
                    ? currentInspector.phoneNumber.replace(this.phoneRegex, "$1-$2-$3")
                    : "";
                this.stateCertificationNumber = currentInspector
                    ? currentInspector.stateCertificate
                    : "";
                this.additionalCertificateList = currentInspector && !currentInspector.isBlocked && !this.isInspectorCertificateExpired(currentInspector) ? currentInspector.additionalCertificateDtoList : [];
                this.certificateURL = currentInspector
                    ? currentInspector.stateCertificateImage
                    : null;
                this.nationalNICETNumber = currentInspector
                    ? currentInspector.nationalCertificate
                    : null;
                this.otherCert = currentInspector
                    ? currentInspector.otherCertificate
                    : null;
                this.isBlocked = currentInspector ? currentInspector.isBlocked : false;
                this.blockedByAuthorityName = currentInspector
                    ? currentInspector.blockedByAuthorityName
                    : null;
                this.blockedByAuthorityEmail = currentInspector
                    ? currentInspector.blockedByAuthorityEmail
                    : null;
                this.inspectorType = currentInspector ? currentInspector.type : null;
            }
            else if (currentInspector.isBlocked) {
                this.inspectorphoneNum = "";
                this.stateCertificationNumber = "";
                this.certificateURL = null;
                this.nationalNICETNumber = null;
                this.additionalCertificateList = [];
                this.otherCert = null;
                this.isBlocked = false;
                this.blockedByAuthorityName = null;
                this.blockedByAuthorityEmail = null;
                this.inspectorType = null;
                alert("The inspector you are trying to select has been blocked by the  " +
                    currentInspector.blockedByAuthorityName +
                    ". Please contact them at " +
                    currentInspector.blockedByAuthorityEmail +
                    " for additional information.");
                // this.inspectionResult.inspectedByCertInspectorId =  -1;
                this.inspectionResult.blockedInspector = true;
                event.value = this.inspectionResult.inspectedByCertInspectorId;
            }
            else {
                //this.inspectionResult.inspectedByCertInspectorId = -1;
                event.value = this.inspectionResult.inspectedByCertInspectorId;
                this.inspectorphoneNum = "";
                this.stateCertificationNumber = "";
                this.certificateURL = null;
                this.nationalNICETNumber = null;
                this.otherCert = null;
                this.additionalCertificateList = [];
                this.isBlocked = false;
                this.blockedByAuthorityName = null;
                this.blockedByAuthorityEmail = null;
                this.inspectorType = null;
                this.isInspectorExpired = true;
                // alert(
                //   "The selected certificate for the inspector you selected is expired." +
                //     " Please have your Administrator update the certificate details in your Preferences or select other inspector for report submission."
                // );
            }
        }
        else {
            this.additionalCertificateList = [];
            this.inspectorphoneNum = "";
            this.stateCertificationNumber = "";
            this.otherCert = "";
            this.nationalNICETNumber = "";
            this.certificateURL = null;
        }
        this.setOtherCert(this.otherCert);
        console.log(this.additionalCertificateList);
    };
    EnterInspectionComponent.prototype.getCertifiedInspectors = function () {
        var _this = this;
        this.isInspectorExpired = false;
        this.subscriptions.push(this.authorityService
            .getCertifiedInspectorsForACompany(this.inspectionResult.inspectedById)
            .subscribe(function (res) {
            var inspectors = [];
            inspectors = res;
            inspectors.unshift({
                id: -1,
                firstName: "Select",
                middleName: "",
                lastName: "",
                isBlocked: false,
            });
            _this.inspectors = inspectors;
            _this.inspectionResult.inspectedByCertInspectorId =
                _this.inspectionResult.inspectedByCertInspectorId &&
                    _this.inspectionResult.inspectedByCertInspectorId !== -1
                    ? _this.inspectionResult.inspectedByCertInspectorId
                    : -1;
        }));
        this.subscriptions.push(this.inspectionService
            .getInspectorDetails(this.inspectionResult.inspectedById)
            .subscribe(function (resp) {
            if (resp) {
                _this.contractorLicense = resp.licenseNumber;
                _this.inspectionDataforSecondPage.address = resp.address;
                _this.inspectionDataforSecondPage.name = resp.name;
                _this.inspectionDataforSecondPage.contact = resp.contact
                    ? resp.contact.replace(_this.phoneRegex, "$1-$2-$3")
                    : "";
            }
        }));
    };
    EnterInspectionComponent.prototype.onSubmit = function () {
        this.loggerService.log("qtd" + this.qtd);
    };
    EnterInspectionComponent.prototype.addDeficiency = function () {
        this.deficiencies.push({
            index: this.deficiencies[this.deficiencies.length - 1]["index"] + 1,
        });
    };
    EnterInspectionComponent.prototype.deleteDeficiency = function (deficiency) {
        /* If deficiency did not have an id */
        if (deficiency.id) {
            this.deficiencies = this.deficiencies.filter(function (x) { return x.id !== deficiency.id; });
        }
        else {
            this.deficiencies = this.deficiencies.filter(function (x) { return x.index !== deficiency.index; });
        }
    };
    EnterInspectionComponent.prototype.setCorrectionDate = function (value, def) {
        if (value) {
            def.dateCorrected =
                this.datepipe.transform(new Date(), "yyyy-MM-dd") + "T00:00:00.000Z";
            def.date = new Date();
        }
    };
    EnterInspectionComponent.prototype.checkHoodSelected = function () {
        if (this.commercialHood &&
            (!this.hoodSelected ||
                (this.hoodSelected === "Others" && !this.othersText))) {
            return true;
        }
        else {
            return false;
        }
    };
    EnterInspectionComponent.prototype.nextPage = function () {
        var _this = this;
        this.submitted = true;
        this.inspectionResult.inspectionType = this.selectedReport;
        this.inspectionDataforSecondPage.inspectionType = this.selectedReport;
        this.inspectionResult.isPACBypassed = +this.pac !== 0 ? false : true;
        var hd = this.hoodSelected;
        if (hd && hd !== 'Kitchen/Range Hood'
            && hd !== 'Paint Spray Booth' && hd !== 'Fume Hood') {
            this.hoodSelected = 'Others';
            this.inspectionResult.hoodType = "Other";
            this.inspectionResult.otherHoodType = this.othersText;
        }
        else {
            this.inspectionResult.hoodType = hd;
        }
        var finalDefi = JSON.parse(JSON.stringify(this.deficiencies));
        finalDefi.forEach(function (x) {
            delete x.date;
            delete x.imageUploadError;
            delete x.index;
        });
        if (this.isInspectorExpired)
            return;
        if (this.inspectionResult.isDeficiencyFound) {
            var defError_1 = false;
            var dateError_1 = false;
            var dateFutureDate_1 = false;
            this.inspectionResult.inspectionDeficiency = this.deficiencies;
            this.deficiencies.forEach(function (x) {
                if (!x.description || (x.description && x.description.trim() === "")) {
                    defError_1 = true;
                }
                if ((x &&
                    x.dateCorrected &&
                    _this.checkForError(x.dateCorrected.split("T")[0])) ||
                    x.dateCorrected === "") {
                    if (x &&
                        x.dateCorrected &&
                        //(this.formatDate(x.dateCorrected.split('T')[0]) > this.formatDate(new Date()))
                        moment(new Date(), "YYYY-MM-DD").isBefore(moment(x.dateCorrected.split("T")[0], "YYYY-MM-DD"))) {
                        dateFutureDate_1 = true;
                    }
                    else {
                        dateError_1 = true;
                    }
                }
                if (x && !x.isCorrected) {
                    x.isCorrected = false;
                }
                if (x.isCorrected) {
                    if (!x.correctionComments ||
                        (x.correctionComments && x.correctionComments.trim() === "")) {
                        if (_this.errorMessages.indexOf("* Enter correction comments for all deficiencies found.") < 0) {
                            _this.errorMessages.push("* Enter correction comments for all deficiencies found.");
                        }
                    }
                    else {
                        if (_this.errorMessages.indexOf("* Enter correction comments for all deficiencies found.") >= 0) {
                            _this.errorMessages.splice(_this.errorMessages.indexOf("* Enter correction comments for all deficiencies found."), 1);
                        }
                    }
                    if (x.correctionComments &&
                        x.correctionComments.trim().length > 1000) {
                        if (_this.errorMessages.indexOf("* Maximum 1000 characters allowed.") <
                            0) {
                            _this.errorMessages.push("* Maximum 1000 characters allowed.");
                        }
                    }
                    else {
                        if (_this.errorMessages.indexOf("* Maximum 1000 characters allowed.") >= 0) {
                            _this.errorMessages.splice(_this.errorMessages.indexOf("* Maximum 1000 characters allowed."), 1);
                        }
                    }
                }
            });
            if (defError_1) {
                if (this.errorMessages.indexOf("* Enter deficiency description for all deficiencies found.") < 0) {
                    this.errorMessages.push("* Enter deficiency description for all deficiencies found.");
                }
            }
            else {
                if (this.errorMessages.indexOf("* Enter deficiency description for all deficiencies found.") >= 0) {
                    this.errorMessages.splice(this.errorMessages.indexOf("* Enter deficiency description for all deficiencies found."), 1);
                }
            }
            if (dateError_1) {
                if (this.errorMessages.indexOf("* Please enter a valid date") < 0) {
                    this.errorMessages.push("* Please enter a valid date");
                }
            }
            else if (dateFutureDate_1) {
                if (this.errorMessages.indexOf("* Future Date is not allowed") < 0) {
                    this.errorMessages.push("* Future Date is not allowed");
                }
            }
            else {
                if (this.errorMessages.indexOf("* Please enter a valid date") >= 0) {
                    this.errorMessages.splice(this.errorMessages.indexOf("* Please enter a valid date"), 1);
                }
                else if (this.errorMessages.indexOf("* Future Date is not allowed") >= 0) {
                    this.errorMessages.splice(this.errorMessages.indexOf("* Future Date is not allowed"), 1);
                }
            }
        }
        if (!this.systemLocation ||
            (this.systemLocation && this.systemLocation.trim() === "")) {
            if (this.errorMessages.indexOf("* Enter System Location field.") < 0) {
                this.errorMessages.push("* Enter System Location field.");
            }
        }
        else if (this.systemLocation && this.systemLocation.length > 128) {
            if (this.errorMessages.indexOf("* System Location should note be more than 128 characters.") < 0) {
                this.errorMessages.push("* System Location should note be more than 128 characters.");
            }
        }
        else {
            if (this.errorMessages.indexOf("* Enter System Location field.") >= 0) {
                this.errorMessages.splice(this.errorMessages.indexOf("* Enter System Location field."), 1);
            }
            if (this.errorMessages.indexOf("* System Location should note be more than 128 characters.") >= 0) {
                this.errorMessages.splice(this.errorMessages.indexOf("* System Location should note be more than 128 characters."), 1);
            }
        }
        if (!this.coverageArea ||
            (this.coverageArea && this.coverageArea.trim() === "")) {
            if (this.errorMessages.indexOf("* Enter Coverage Area field.") < 0) {
                this.errorMessages.push("* Enter Coverage Area field.");
            }
        }
        else if (this.coverageArea && this.coverageArea.length > 128) {
            if (this.errorMessages.indexOf("* Coverage Area should note be more than 128 characters.") < 0) {
                this.errorMessages.push("* Coverage Area should note be more than 128 characters.");
            }
        }
        else {
            if (this.errorMessages.indexOf("* Enter Coverage Area field.") >= 0) {
                this.errorMessages.splice(this.errorMessages.indexOf("* Enter Coverage Area field."), 1);
            }
            if (this.errorMessages.indexOf("* Coverage Area should note be more than 128 characters.") >= 0) {
                this.errorMessages.splice(this.errorMessages.indexOf("* Coverage Area should note be more than 128 characters."), 1);
            }
        }
        var name = "* Enter Inspected By field.";
        if (this.userRole === "ROLE_INSPECTOR") {
            name = "* Check Preferences for valid Inspector Certifications.";
        }
        if (this.userRole !== "ROLE_INSPECTOR") {
            if (+this.inspectionResult.inspectedById === -1) {
                if (this.errorMessages.indexOf("* Enter Inspection Company field.") < 0) {
                    this.errorMessages.push("* Enter Inspection Company field.");
                }
            }
            else {
                if (this.errorMessages.indexOf("* Enter Inspection Company field.") >= 0) {
                    this.errorMessages.splice(this.errorMessages.indexOf("* Enter Inspection Company field."), 1);
                }
            }
        }
        if (!(this.inspectionResult.inspectedByCertInspectorId) || (+this.inspectionResult.inspectedByCertInspectorId === -1 &&
            this.inspectors.length !== 0)) {
            if (this.errorMessages.indexOf("* Enter Inspected By field.") < 0) {
                this.errorMessages.push("* Enter Inspected By field.");
            }
        }
        else {
            if (this.errorMessages.indexOf(name) >= 0) {
                this.errorMessages.splice(this.errorMessages.indexOf(name), 1);
            }
        }
        if (+this.inspectionResult.blockedInspector) {
            if (this.errorMessages.indexOf("* Enter a valid Inspector.") < 0) {
                this.errorMessages.push("*  Enter a valid Inspector.");
            }
        }
        else {
            if (this.errorMessages.indexOf("*  Enter a valid Inspector.") >= 0) {
                this.errorMessages.splice(this.errorMessages.indexOf("*  Enter a valid Inspector."), 1);
            }
        }
        if (this.commercialHood &&
            (!this.hoodSelected ||
                (this.hoodSelected === "Others" && !this.othersText))) {
            if (this.errorMessages.indexOf("* Enter Hood Type.") < 0) {
                this.errorMessages.push("* Enter Hood Type.");
            }
        }
        else {
            if (this.errorMessages.indexOf("* Enter Hood Type.") >= 0) {
                this.errorMessages.splice(this.errorMessages.indexOf("* Enter Hood Type."), 1);
            }
        }
        if (this.errorMessages.length === 0) {
            this.showInspectionQuestions = true;
            if (this.viewReportData && this.viewReportData.dialogRef) {
                var elem = document.getElementsByClassName("custom-container-headings");
                var a = tslib_1.__spread(elem).filter(function (e) { return e.innerText === "Inspection Result"; });
                a[0].scrollIntoView(true);
            }
            else {
                window.scrollTo(0, 0);
            }
        }
        else {
            var invalidControl = this.el.nativeElement.querySelector(".ng-invalid");
            if (invalidControl) {
                invalidControl.focus();
                invalidControl.scrollIntoView();
            }
        }
    };
    EnterInspectionComponent.prototype.redirectDashboard = function () {
        if (this.router.url.indexOf("fileCabinet") > -1) {
            this.viewReportData.dialogRef.close();
            this.router.navigate(["user/general/fileCabinet"]);
            return;
        }
        if (!this.addEditProperty && !this.isViewMode) {
            this.router.navigate([
                "user/inspection/searchInspectionProperties/getInspectionAssetsByProperty",
            ], {
                queryParams: {
                    id: this.propertyId,
                    pac: this.pac,
                    isSimplifiedReportEnabled: this.isSimplifiedReportEnabled,
                    isImageUploadEnabled: this.isImageUploadEnabled,
                },
            });
        }
        else if (this.isViewMode) {
            this.location.back();
        }
        else {
            this.router.navigate(["user/inspection/addEditProperty/getInspectionAssetsByProperty"], {
                queryParams: {
                    id: this.propertyId,
                    pac: this.pac,
                    isSimplifiedReportEnabled: this.isSimplifiedReportEnabled,
                    isImageUploadEnabled: this.isImageUploadEnabled,
                },
            });
        }
    };
    EnterInspectionComponent.prototype.setType = function (certType) {
        this.inspectionResult.certType = certType;
    };
    EnterInspectionComponent.prototype.setColor = function (colo) {
        this.inspectionResult.certificateGiven = colo;
    };
    EnterInspectionComponent.prototype.setHood = function (hood, others) {
        this.inspectionResult.hoodType = hood;
        if (others) {
            this.inspectionResult.hoodType = this.othersText;
        }
    };
    EnterInspectionComponent.prototype.setCentralMonitor = function (option) {
        if (option === "Yes") {
            this.inspectionResult.isCentralMonitoring = true;
        }
        else {
            this.inspectionResult.isCentralMonitoring = false;
            this.setMonitorCompany("");
            this.setControlPanel("");
            this.setModelNumber("");
        }
    };
    EnterInspectionComponent.prototype.setDeficiency = function (option) {
        if (option === "Yes") {
            this.inspectionResult.isDeficiencyFound = true;
        }
        else {
            this.inspectionResult.isDeficiencyFound = true;
            if (this.deficiencies && this.deficiencies.length > 0) {
                if (confirm("All added deficiencies will be lost.Do you want to continue?")) {
                    if (this.errorMessages.indexOf("* Enter deficiency description for all deficiencies found.") >= 0) {
                        this.errorMessages.splice(this.errorMessages.indexOf("* Enter deficiency description for all deficiencies found."), 1);
                    }
                    if (this.errorMessages.indexOf("* Please enter a valid date") >= 0) {
                        this.errorMessages.splice(this.errorMessages.indexOf("* Please enter a valid date"), 1);
                    }
                    if (this.errorMessages.indexOf("* Future Date is not allowed") >= 0) {
                        this.errorMessages.splice(this.errorMessages.indexOf("* Future Date is not allowed"), 1);
                    }
                    if (this.errorMessages.indexOf("* Enter correction comments for all deficiencies found.") >= 0) {
                        this.errorMessages.splice(this.errorMessages.indexOf("* Enter correction comments for all deficiencies found."), 1);
                    }
                    this.deficiencies = [{ index: 0 }];
                    this.inspectionResult.isDeficiencyFound = false;
                }
            }
        }
    };
    EnterInspectionComponent.prototype.setMonitorCompany = function (monitoringCompany) {
        this.inspectionResult.monitoringCompany = monitoringCompany;
    };
    EnterInspectionComponent.prototype.setControlPanel = function (manufacturer) {
        this.inspectionResult.manufacturer = manufacturer;
    };
    EnterInspectionComponent.prototype.setModelNumber = function (modelNumber) {
        this.inspectionResult.modelNumber = modelNumber;
    };
    EnterInspectionComponent.prototype.setOtherCert = function (cert) {
        this.inspectionResult.otherCertification = cert;
    };
    EnterInspectionComponent.prototype.setSystemLocation = function (loc) {
        this.inspectionResult.propertyAsset.assetLocation = loc;
    };
    EnterInspectionComponent.prototype.setSystemCoverage = function (cov) {
        this.inspectionResult.propertyAsset.assetCoverage = cov;
    };
    EnterInspectionComponent.prototype.customTrackBy = function (index, obj) {
        return index;
    };
    EnterInspectionComponent.prototype.backButton = function () {
        this.showInspectionQuestions = !this.showInspectionQuestions;
    };
    EnterInspectionComponent.prototype.showErrorMessages = function (data) {
        this.errorMessages = data;
    };
    EnterInspectionComponent.prototype.clear = function () {
        this.certificationGiven = null;
    };
    EnterInspectionComponent.prototype.uploadFile = function (deficiency, event, files) {
        var _this = this;
        var image = files.item(0);
        if (image.size < 1097152) {
            if (image.type.toLowerCase() === "image/gif" ||
                image.type.toString() === "image/jpeg" ||
                image.type.toString().toLowerCase() === "image/png") {
                deficiency.imageUploadError = "";
                var reader_1 = new FileReader();
                reader_1.readAsDataURL(image);
                deficiency.image = [{}];
                reader_1.onload = function () {
                    _this.base64logoImage = reader_1.result.toString();
                    deficiency.image[0].imageName = image.name;
                    deficiency.image[0].attachment = _this.base64logoImage;
                    _this.image = reader_1.result.toString();
                    event.value = "";
                };
                reader_1.onerror = function (error) {
                    _this.loggerService.log("Error on Loading Image", error);
                    deficiency.imageUploadError = "Error while Loading File";
                };
            }
            else {
                deficiency.imageUploadError = "Invalid File Type";
            }
        }
        else if (image.size > 1097152) {
            deficiency.imageUploadError = "File Size is greater than 1MB";
        }
        else if (image.type.toLowerCase() !== "gif" ||
            image.type.toString() !== "jpg" ||
            image.type.toString() !== "application/pdf") {
            deficiency.imageUploadError = "Invalid File Type";
        }
    };
    EnterInspectionComponent.prototype.checkForError = function (pickerInput) {
        if (!pickerInput || pickerInput === "") {
            return true;
            // } else if (pickerInput.indexOf('-') < 0) {
            //   return true;
        }
        else if (!moment(pickerInput, "YYYY-MM-DD", true).isValid()) {
            return true;
        }
        else if (moment(new Date(), "YYYY-MM-DD").isBefore(moment(pickerInput, "YYYY-MM-DD"))) {
            console.log("picker" + moment(pickerInput, "YYYY-MM-DD"));
            console.log("Todays Date" + moment(new Date()), "YYYY-MM-DD");
            return true;
        }
        else {
            return false;
        }
    };
    EnterInspectionComponent.prototype.updateDeficiencyDate = function (deficiency, pickerInput) {
        if (typeof pickerInput === "object") {
            if (this.errorMessages.indexOf("* Please enter a valid date") >= 0) {
                this.errorMessages.splice(this.errorMessages.indexOf("* Please enter a valid date"), 1);
            }
            else if (this.errorMessages.indexOf("* Future Date is not allowed") >= 0) {
                this.errorMessages.splice(this.errorMessages.indexOf("* Future Date is not allowed"), 1);
            }
            deficiency.dateCorrected =
                deficiency && deficiency.date
                    ? this.datepipe.transform(deficiency.date._d, "yyyy-MM-dd") +
                        "T00:00:00.000Z"
                    : "";
        }
        else {
            if (!pickerInput || pickerInput === "") {
                if (this.errorMessages.indexOf("* Please enter a valid date") < 0) {
                    this.errorMessages.push("* Please enter a valid date");
                }
            }
            else if (pickerInput.indexOf("-") < 0) {
                if (this.errorMessages.indexOf("* Please enter a valid date") < 0) {
                    this.errorMessages.push("* Please enter a valid date");
                }
            }
            else if (!moment(pickerInput, "MM-DD-YYYY", true).isValid()) {
                if (this.errorMessages.indexOf("* Please enter a valid date") < 0) {
                    this.errorMessages.push("* Please enter a valid date");
                }
            }
            else if (moment(pickerInput, "MM-DD-YYYY").isAfter(moment(new Date(), "MM-DD-YYYY"))) {
                this.errorMessages.push("* Future Date is not allowed");
            }
            else {
                if (this.errorMessages.indexOf("* Please enter a valid date") >= 0) {
                    this.errorMessages.splice(this.errorMessages.indexOf("* Please enter a valid date"), 1);
                }
                else if (this.errorMessages.indexOf("* Future Date is not allowed") >= 0) {
                    this.errorMessages.splice(this.errorMessages.indexOf("* Future Date is not allowed"), 1);
                }
                deficiency.dateCorrected =
                    this.datepipe.transform(pickerInput, "yyyy-MM-dd") + "T00:00:00.000Z";
            }
        }
    };
    EnterInspectionComponent.prototype.getErrorMsgDate = function (pickerInput) {
        if (this.errorMessages.indexOf("* Future Date is not allowed") >= 0) {
            return "* Future Date is not allowed";
        }
        else if (this.errorMessages.indexOf("* Please enter a valid date") >= 0) {
            return "* Please enter a valid date";
        }
    };
    EnterInspectionComponent.prototype.newTabImage = function (url) {
        var image = new Image();
        image.src = url;
        var w = window.open("", "_blank");
        w.document.write(image.outerHTML);
        w.document.close();
    };
    EnterInspectionComponent.prototype.formatDate = function (date) {
        var d = new Date(date);
        var month = "" + (d.getMonth() + 1);
        var day = "" + d.getDate();
        var year = d.getFullYear();
        if (month.length < 2) {
            month = "0" + month;
        }
        if (day.length < 2) {
            day = "0" + day;
        }
        return [month, day, year].join("-");
    };
    EnterInspectionComponent.prototype.getInspectionOptionsStyle = function (inspector) {
        if (inspector.isBlocked === true || this.isInspectorCertificateExpired(inspector)) {
            return { "background-color": "lightgrey", color: "#495057" };
        }
    };
    EnterInspectionComponent.prototype.isInspectorCertificateExpired = function (inspector) {
        var additionalCertification = inspector.additionalCertificateDtoList;
        var nationalCertExpiryDate = inspector.nationalCertExpiryDate;
        var otherCertExpiryDate = inspector.otherCertExpiryDate;
        var stateCertExpiryDate = inspector.stateCertExpiryDate;
        var expiredAdditionalCertificates = additionalCertification ? additionalCertification.filter(function (o) {
            return moment(o.otherCertExpiryDate, "MM-DD-YYYY") < moment();
        }) : [];
        if (nationalCertExpiryDate == null &&
            otherCertExpiryDate == null &&
            stateCertExpiryDate == null &&
            expiredAdditionalCertificates.length == 0) {
            return false;
        }
        else if (moment(nationalCertExpiryDate, "MM-DD-YYYY") < moment() ||
            moment(otherCertExpiryDate, "MM-DD-YYYY") < moment() ||
            moment(stateCertExpiryDate, "MM-DD-YYYY") < moment()) {
            return true;
        }
        if (expiredAdditionalCertificates && expiredAdditionalCertificates.length > 0)
            return true;
        return false;
    };
    EnterInspectionComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (x) {
            x.unsubscribe();
        });
    };
    return EnterInspectionComponent;
}());
export { EnterInspectionComponent };
